.ProfileModal {
  z-index: 10;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: fit-content;
  border-radius: var(--sp-xs);
  right: var(--sp-l);
  box-shadow: var(--ion-box-shadow-light);
  position: fixed;
  background-color: var(--ion-color-white);
  transition: top 0.5s 0s ease;

  &.open {
    top: 60px;
  }

  &.closed {
    top: -360px;
  }

  .container {
    padding: var(--sp-md) var(--sp-md) var(--sp-ms);

    .ActionButton {
      position: absolute;
      right: var(--sp-ms);
      top: var(--sp-ms);
    }

    .ProfileCardContent {
      display: flex;
      flex-direction: column;

      .commentReport {
        margin-bottom: 1rem;
        width: 100%;
      }
      .accessSettings {
        margin-bottom: 1rem;
        width: 100%;
      }

      .profileContainer {
        gap: var(--sp-ms);

        > .UserPicture,
        > .profilePicture {
          width: 78px;
          height: 78px;
          align-self: flex-start;

          .UserPicture.largeXL .InitialsPicture {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
          }

          * {
            width: inherit;
            height: inherit;
          }
        }

        .informations {
          align-items: flex-start;

          .details {
            align-items: flex-start;

            .profileDetail {
              margin-bottom: var(--sp-s);
            }
          }
        }
      }
    }
  }
}
