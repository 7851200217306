@import "@france/superelements/theme/global.scss";
@import "./variables.scss";
@import "./size.scss";
@import "./font.scss";
@import "./icon.scss";
@import "./scrollBars.scss";

:root {
  --screen-y: 0;
  --screen-x: 0;

  --window-y: 0;
  --window-x: 0;

  --rem-size: 14px;
}

html {
  font-size: var(--rem-size) !important;
}

ion-app.keyboardTime {
  margin-bottom: 0px;
}

ion-spinner {
  width: calc(100vw - 2rem);
  margin: 1rem;
  min-height: 3rem;
  color: var(--ion-color-primary);
}

ion-header {
  box-shadow: none !important;
}

input {
  outline: none !important;
}

.FullScreenModal {
  --height: 100vh;
  --width: 100vw;
}

div[role="checkbox"] {
  position: relative;
  height: 1.4rem;
  width: 1.4rem;

  border: 1px solid var(--ion-color-primary);
  border-radius: 0.4rem;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);

  cursor: pointer;

  > svg {
    display: none;
    position: absolute;
    height: 85% !important;
    width: 85% !important;
    top: 7.5%;
    left: 7.5%;

    stroke-width: 3;
  }

  &[aria-checked="false"] {
    background-color: transparent;
  }

  &[aria-checked="mixed"] {
    > svg:last-child {
      display: block;
    }
  }

  &[aria-checked="true"] {
    > svg:first-child {
      display: block;
    }
  }
}

a,
button {
  text-decoration: none;
  color: var(--ion-color-black);
  &[disabled] {
    opacity: 0.5;
    color: var(--ion-color-grey-dark);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: unset;
}

ion-input {
  --highlight-color-focused: transparent;
}

.input-highlight {
  display: none !important;
}

.FieldRadioDropdown > div > menu > button[role="menuitem"] {
  align-items: center;
  border-bottom: 1px solid var(--ion-color-grey-medium-light);
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: auto;
  padding: 1rem;
  white-space: break-spaces;
  text-align: left;
  line-height: 1.2;
}
