.FilterVolumeRange {
  .rangeBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .inputRange {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
    }

    .inputBox {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--ion-color-grey-medium-light);
      padding: 0.5rem 0.75rem;
    }

    input {
      width: 100%;
      border: unset;
    }

    ion-range {
      --knob-size: 2rem;
    }
  }
}
